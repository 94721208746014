<template>
  <vx-card title="Sales Target Import">
    <div class="vx-row mb-6" style="width: 50%">
      <div class="vx-col sm:w-1/3 w-full flex items-center">
        <span>Territory</span>
      </div>
      <div class="vx-col sm:w-2/3 w-full">
        <v-select
          label="name"
          :searchable="true"
          :options="optionTerritory"
          :clearable="false"
          v-model="selectedTerritory"
        >
          <template slot="option" slot-scope="option">
            {{ option.code }} {{ option.name }}
          </template>
          <template slot="selected-option" slot-scope="option">
            {{ option.code }} {{ option.name }}
          </template>
        </v-select>
      </div>
    </div>
    <div class="vx-row mb-6" style="width: 50%">
      <div class="vx-col sm:w-1/3 w-full flex items-center">
        <span>Year</span>
      </div>
      <div class="vx-col sm:w-2/3 w-full">
        <datepicker
          name="year"
          format="yyyy"
          :inline="false"
          v-model="year"
          minimum-view="year"
          placeholder="Select Year"
        ></datepicker>
      </div>
    </div>
    <div class="vx-row mb-6" style="width: 50%">
      <div class="vx-col sm:w-1/3 w-full flex items-center">
        <span>Target Type</span>
      </div>
      <div class="vx-col sm:w-2/3 w-full">
        <v-select
          :searchable="true"
          :options="optionTargetType"
          :clearable="false"
          v-model="selectedTargetType"
        >
        </v-select>
      </div>
    </div>

    <!-- <div
      class="vx-row mb-6"
      style="width: 50%"
      v-if="this.selectedTargetType == 'Monthly'"
    >
      <div class="vx-col sm:w-1/3 w-full flex items-center">
        <span>Target Unit</span>
      </div>
      <div class="vx-col sm:w-2/3 w-full">
        <v-select
          :filterable="true"
          :options="optionMonth"
          :clearable="false"
          v-model="selectedMonth"
        />
      </div>
    </div>
    <div
      class="vx-row mb-6"
      style="width: 50%"
      v-if="this.selectedTargetType == 'Weekly'"
    >
      <div class="vx-col sm:w-1/3 w-full flex items-center">
        <span>Target Unit</span>
      </div>
      <div class="vx-col sm:w-2/3 w-full">
        <v-select
          :filterable="true"
          :options="optionWeek"
          :clearable="false"
          v-model="selectedWeek"
        />
      </div>
    </div> -->
    <!-- <div class="vx-row mb-6" style="width: 50%">
      <div class="vx-col sm:w-1/3 w-full flex items-center">
        <span>Attribute Type</span>
      </div>
      <div class="vx-col sm:w-2/3 w-full">
        <v-select :searchable="true" :options="optionAttributeType" :clearable="false" v-model="selectedAttributeType">
        </v-select>
      </div>
    </div> -->

    <div class="vx-row mb-6" style="width: 50%">
      <div class="vx-col sm:w-1/3 w-full flex items-center"></div>
      <div class="vx-col sm:w-2/3 w-full">
        <vs-button
          class="mt-2"
          color="success"
          type="border"
          icon-pack="feather"
          icon="icon-download"
          @click="handleDownloadTemplate()"
          >Template Download</vs-button
        >
      </div>
    </div>
    <div class="vx-row mb-6" style="width: 50%">
      <div class="vx-col sm:w-1/3 w-full flex items-center">
        <span>File (xls, xlsx)</span>
      </div>
      <div class="vx-col sm:w-2/3 w-full">
        <input
          type="file"
          ref="fileInput"
          accept=".xlsx, .xls"
          @change="handleChangeFile"
        />
      </div>
    </div>
    <div class="vx-row mb-6" style="width: 50%">
      <div class="vx-col sm:w-1/3 w-full flex items-center">
        <span>Option</span>
      </div>
      <div class="vx-col sm:w-2/3 w-full">
        <v-select
          :filterable="true"
          :options="options"
          :clearable="false"
          v-model="selectedOption"
        />
      </div>
    </div>
    <div
      class="vx-row mb-6"
      style="width: 50%"
      v-if="this.selectedOption != 'New'"
    >
      <div class="vx-col sm:w-1/3 w-full flex items-center">
        <span>Sales Target</span>
      </div>
      <div class="vx-col sm:w-2/3 w-full">
        <v-select
          :filterable="true"
          :options="optionSalesTarget"
          :clearable="false"
          v-model="selectedSalesTarget"
        />
      </div>
    </div>
    <div class="vx-row mb-6" style="width: 50%">
      <div class="vx-col sm:w-1/3 w-full flex items-center">
        <span>Description</span>
      </div>
      <div class="vx-col sm:w-2/3 w-full">
        <vs-textarea v-model="description" width="100%" />
      </div>
    </div>
    <div class="vx-row mb-6" style="width: 50%">
      <div class="vx-col sm:w-1/3 w-full flex items-center"></div>
      <div class="vx-col sm:w-2/3 w-full">
        <vs-button
          v-if="hasPermission('create')"
          class="mt-2"
          color="success"
          type="border"
          icon-pack="feather"
          icon="icon-upload"
          @click="handleImport()"
          >Import</vs-button
        >
      </div>
    </div>

    <div class="vx-row">
      <div class="vx-col sm:w-1/1 w-full mb-base">
        <vs-tabs v-model="activeTab">
          <vs-tab label="Import">
            <data-table-log-import
              :baseUrl="this.baseUrl"
              :draw="draw"
              @logImportLine="handleLogImportLine"
            ></data-table-log-import>
          </vs-tab>
          <vs-tab label="Import Line">
            <data-table-log-import-line
              :baseUrl="this.baseUrl"
              :logImportID="this.logImportID"
            ></data-table-log-import-line>
          </vs-tab>
        </vs-tabs>
      </div>
    </div>
  </vx-card>
</template>
<script>
import Datepicker from "vuejs-datepicker";
import moment from "moment";
import vSelect from "vue-select";
import DataTableLogImport from "./DataTableLogImport.vue";
import DataTableLogImportLine from "./DataTableLogImportLine.vue";
export default {
  components: {
    Datepicker,
    vSelect,
    DataTableLogImport,
    DataTableLogImportLine,
  },
  data() {
    return {
      baseUrl: "/api/sfa/v1/sales-target",
      activeTab: 0,

      optionTerritory: [],
      selectedTerritory: null,
      year: null,
      optionTargetType: ["Monthly", "Weekly"],
      selectedTargetType: null,
      optionMonth: [
        "January",
        "February",
        "March",
        "April",
        "May",
        "June",
        "July",
        "August",
        "September",
        "October",
        "November",
        "December",
      ],
      selectedMonth: null,
      optionWeek: [],
      selectedWeek: null,
      optionAttributeType: [
        "General",
        "Principal",
        "Brand",
        "Product Category",
        "Distribution Channel",
        "Sales Channel",
      ],
      selectedAttributeType: null,

      options: ["New", "Add", "Replace"],
      selectedOption: "New",
      file: null,
      optionSalesTarget: [],
      selectedSalesTarget: null,
      description: "",

      draw: 0,
      logImportID: 0,
    };
  },
  methods: {
    setDefault() {
      this.activeTab = 0;
      this.selectedTargetType = this.optionTargetType[0];
      this.selectedTerritory = this.optionTerritory[0];
      this.selectedOption = "New";
      this.selectedSalesTarget = null;
      this.month = null;
      this.description = "";
      this.file = null;
      this.logImportID = 0;
      this.$refs.fileInput.value = null;
    },
    handleLogImportLine(id) {
      this.activeTab = 1;
      this.logImportID = id;
    },
    handleDownloadTemplate() {
      var fileTitle = "SALES_TARGET_TEMPLATE_";
      var currentYear

      if (this.selectedTerritory) {
        fileTitle +=
          this.selectedTerritory.code + "_" + this.selectedTerritory.name;
      } else {
        this.$vs.notify({
          color: "danger",
          title: "Error",
          text: "Territory is required",
          position: "top-right",
          iconPack: "feather",
          icon: "icon-x-circle",
        });
        return false;
      }

      if (this.year) {
        fileTitle += "_" + moment(this.year).format("YYYY");
      } else {
        currentYear = parseInt(new Date().getFullYear())
        fileTitle += "_" + currentYear;
      }

      if (this.selectedTargetType) {
        fileTitle += "_" + this.selectedTargetType;
      }

      // if (this.selectedAttributeType) {
      //   fileTitle += "_" + this.selectedAttributeType;
      // } else {
      //   this.$vs.notify({
      //     color: "danger",
      //     title: "Error",
      //     text: "Attribute type is required",
      //     position: "top-right",
      //     iconPack: "feather",
      //     icon: "icon-x-circle",
      //   });
      //   return false;
      // }

      this.$vs.loading();
      this.$http
        .get(this.baseUrl + "/import/template", {
          params: {
            territory_id: this.selectedTerritory.id,
            year: this.year ? moment(this.year).format("YYYY") : currentYear,
            target_type: this.selectedTargetType,
            month: this.selectedMonth,
            sales_quota_week_id: this.selectedWeek
              ? this.selectedWeek.id
              : null,
            attribute_type: this.selectedAttributeType,
          },
          responseType: "arraybuffer",
          headers: {
            Accept: "application/octet-stream",
          },
        })
        .then((resp) => {
          this.$vs.loading.close();
          if (resp.status == "error") {
            this.$vs.notify({
              color: "danger",
              title: "Error",
              text: resp.message,
              position: "top-right",
              iconPack: "feather",
              icon: "icon-x-circle",
            });
          } else {
            var fileURL = window.URL.createObjectURL(new Blob([resp]));
            var fileLink = document.createElement("a");
            fileLink.href = fileURL;

            fileLink.setAttribute(
              "download",
              fileTitle.toUpperCase() + ".xlsx"
            );
            document.body.appendChild(fileLink);
            fileLink.click();
          }
        });
    },
    handleImport() {
      if (!this.selectedTerritory) {
        this.$vs.notify({
          color: "danger",
          title: "Error",
          text: "Territory is required",
          position: "top-right",
          iconPack: "feather",
          icon: "icon-x-circle",
        });
        return false;
      }

      if (!this.year) {
        this.$vs.notify({
          color: "danger",
          title: "Error",
          text: "Year is required",
          position: "top-right",
          iconPack: "feather",
          icon: "icon-x-circle",
        });
        return false;
      }

      if (!this.selectedTargetType) {
        this.$vs.notify({
          color: "danger",
          title: "Error",
          text: "Target Type is required",
          position: "top-right",
          iconPack: "feather",
          icon: "icon-x-circle",
        });
        return false;
      }

      // if (!this.selectedAttributeType) {
      //   this.$vs.notify({
      //     color: "danger",
      //     title: "Error",
      //     text: "Attribute type is required",
      //     position: "top-right",
      //     iconPack: "feather",
      //     icon: "icon-x-circle",
      //   });
      //   return false;
      // }

      if (this.selectedOption != "New") {
        if (!this.selectedSalesTarget) {
          this.$vs.notify({
            color: "danger",
            title: "Error",
            text: "Sales target is required",
            position: "top-right",
            iconPack: "feather",
            icon: "icon-x-circle",
          });
          return false;
        }
      }

      if (!this.file) {
        this.$vs.notify({
          color: "danger",
          title: "Error",
          text: "File is required",
          position: "top-right",
          iconPack: "feather",
          icon: "icon-x-circle",
        });
        return false;
      }

      let params = {
        territory_id: this.selectedTerritory.id,
        year: parseInt(moment(this.year).format("YYYY")),
        target_type: this.selectedTargetType,
        month: this.selectedMonth,
        sales_quota_week_id: this.selectedWeek ? this.selectedWeek.id : null,
        attribute_type: this.selectedAttributeType,
        option: this.selectedOption,
        sales_target_id: this.selectedSalesTarget
          ? this.selectedSalesTarget.id
          : null,
        description: this.description,
      };

      let formData = new FormData();
      formData.append("file", this.file);
      formData.append("data", JSON.stringify(params));

      this.$vs.loading();
      this.$http
        .post(this.baseUrl + "/import/create", formData)
        .then((resp) => {
          if (resp.code == 200) {
            this.$vs.notify({
              color: "success",
              title: "Success",
              text: resp.message,
              position: "top-right",
              iconPack: "feather",
              icon: "icon-x-circle",
            });

            this.$vs.loading.close();
            this.draw++;
            this.setDefault();
          } else {
            this.$vs.loading.close();
            this.$vs.notify({
              color: "danger",
              title: "Error",
              text: resp.message,
              position: "top-right",
              iconPack: "feather",
              icon: "icon-x-circle",
            });
          }
        })
        .catch((error) => {
          this.$vs.loading.close();
          console.log(error);
        });

      console.log(params);
    },
    handleChangeFile(event) {
      this.file = event.target.files ? event.target.files[0] : null;
      // console.log(this.file);
      if (this.file) {
        const reader = new FileReader();

        // reader.onload = (e) => {
        //   /* Parse data */
        //   const bstr = e.target.result;
        //   const wb = XLSX.read(bstr, { type: "binary" });
        //   /* Get first worksheet */
        //   const wsname = wb.SheetNames[0];
        //   const ws = wb.Sheets[wsname];
        //   /* Convert array of arrays */
        //   const data = XLSX.utils.sheet_to_json(ws, { header: 1 });
        // };

        reader.readAsBinaryString(this.file);
      }
    },
    getSalesTarget() {
      if (this.selectedTerritory)
        if (this.selectedTerritory.id && this.selectedOption != "New") {
          this.$vs.loading();
          this.$http
            .get(this.baseUrl + "/sales-target", {
              params: {
                order: "code",
                sort: "desc",
                route_group_id: this.selectedRouteGroup.id,
                territory_id: this.selectedTerritory.id,
                zone_id: this.selectedZone.id,
              },
            })
            .then((resp) => {
              if (resp.code == 200) {
                if (resp.data.records) {
                  this.optionRoutePlan = resp.data.records;
                } else {
                  this.optionRoutePlan = [];
                }
              } else {
                this.optionRoutePlan = [];
              }

              this.$vs.loading.close();
            });
        } else {
          this.optionRoutePlan = [];
        }
    },
    getTerritory() {
      this.$vs.loading();
      this.$http
        .get(this.baseUrl + "/territory", {
          params: {
            length: 0,
            order: "name",
            sort: "asc",
          },
        })
        .then((resp) => {
          if (resp.code == 200) {
            if (resp.data.records) {
              this.optionTerritory = resp.data.records;
              if (this.optionTerritory.length > 0) {
                this.selectedTerritory = this.optionTerritory[0];
              } else {
                this.optionTerritory = [];
                this.selectedTerritory = {};
              }
            } else {
              this.optionTerritory = [];
              this.selectedTerritory = {};
            }
            this.$vs.loading.close();
          } else {
            this.$vs.loading.close();
          }
        });
    },
  },
  mounted() {
    this.$store.dispatch("user/getPermissions", "master-sales-target");
    this.getTerritory();
  },
  watch: {
    selectedTerritory() {},

    selectedOption() {
      // this.getSalesTarget();
    },
  },
};
</script>
